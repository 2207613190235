import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedTable from '../components/EnhancedTable';
import TableHeader from '../components/TableHeader';
import { getPendingCandidatesApi } from '../actions/pendingCandidates';
import {
  CANDIDATE_FIELDS,
  CANDIDATE_FIELDS_HEADER_MAPPING,
} from '../../constants/candidateDataConfig';
import { CSVLink } from 'react-csv';
import { clearAllBannerMessages } from '../actions/bannerMessages';
import { dateComparator } from '../../helpers/tableComparators.js';
import { Button } from '@amzn/awsui-components-react';
const PendingCandidatesPage = () => {
  const [currentSelection, setCurrentSelection] = useState([]);
  const pendingCandidates = useSelector((state) => state.pendingCandidates);
  const pendingCandidatesLoading = useSelector(
    (state) => state.loading.pendingCandidatesLoading
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearAllBannerMessages());
    dispatch(getPendingCandidatesApi());
  }, []);
  const specialSortableColumnsComparatorsObj = {
    dayOne: dateComparator('dayOne'),
  };
  const HeaderComponent = () => (
    <TableHeader
      title="Pending Candidates"
      onRefresh={() => dispatch(getPendingCandidatesApi())}
      buttons={[
        <Button key="exportCSV">
          <CSVLink
            data={
              currentSelection.length === 0
                ? pendingCandidates
                : currentSelection
            }
            filename="pending_candidates.csv"
          >
            <span className="export-csv-btn">Export CSV</span>
          </CSVLink>
        </Button>,
      ]}
    />
  );

  return (
    <EnhancedTable
      headers={CANDIDATE_FIELDS.map(
        (field) => CANDIDATE_FIELDS_HEADER_MAPPING[field]
      )}
      dataKeys={CANDIDATE_FIELDS}
      data={pendingCandidates}
      loading={pendingCandidatesLoading}
      loadingText="Loading pending candidates"
      setCurrentSelection={setCurrentSelection}
      currentSelection={currentSelection}
      selectionType="multi"
      HeaderComponent={HeaderComponent}
      specialSortableColumnsComparatorsObj={
        specialSortableColumnsComparatorsObj
      }
    />
  );
};

export default PendingCandidatesPage;
