export const CANDIDATE_FIELDS = [
  'candidateId',
  'candidateEmail',
  'classId',
  'dayOne',
  'firstName',
  'lastName',
  'ouId',
  'site',
  'language',
];

export const CANDIDATE_FIELDS_DB_MAPPING = {
  id: 'candidateId',
  email: 'candidateEmail',
  class_id: 'classId',
  day_one: 'dayOne',
  fname: 'firstName',
  lname: 'lastName',
  ou_id: 'ouId',
  site: 'site',
  language: 'language',
};

export const CANDIDATE_FIELDS_HEADER_MAPPING = {
  candidateId: 'Candidate Id',
  candidateEmail: 'Candidate Email',
  classId: 'Class Id',
  dayOne: 'Day One',
  firstName: 'First Name',
  lastName: 'Last Name',
  ouId: 'OU',
  site: 'Site',
  language: 'Language',
};

export const EDITABLE_FIELDS = [
  { key: 'firstName', type: 'string' },
  { key: 'lastName', type: 'string' },
  { key: 'classId', type: 'string' },
  { key: 'dayOne', type: 'date', format: 'MM/DD/YYYY' },
  { key: 'language', type: 'string' },
  { key: 'ouId', type: 'string' },
  { key: 'site', type: 'string' },
];

export const EDITABLE_FIELDS_LABEL = {
  firstName: 'First Name',
  lastName: 'Last Name',
  classId: 'Class Id',
  dayOne: 'Day One',
  language: 'Language',
  ouId: 'OU',
  site: 'Site',
};

export const EDITABLE_FIELDS_WITH_WARNING = ['classId', 'dayOne', 'language'];

export const EDITABLE_FIELDS_WARNINGS = {
  classId: 'When updating Class ID, ensure Day One is updated as necessary',
  dayOne: 'When updating Day One, ensure Class ID is updated as necessary',
  language: 'When updating Language, ensure Class ID is updated as necessary',
};
