import React from 'react';
import '../styles/enhancedTable.scss';
import {
  Table,
  TablePageSizeSelector,
  TableSelection,
  TableSorting,
  TablePagination,
  TablePreferences,
  TablePropertyFiltering,
} from '@amzn/awsui-components-react/polaris';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import PropTypes from 'prop-types';
import {
  userSiteToTimeZone,
  userSiteToTimeZoneShortList,
} from '../../constants/timezones';
const EnhancedTable = ({
  headers,
  dataKeys,
  data,
  loading,
  loadingText,
  HeaderComponent,
  EmptyComponent,
  NoMatchComponent,
  currentSelection = [],
  setCurrentSelection,
  selectionType = 'single',
  isItemDisabled = () => false,
  specialSortableColumnsComparatorsObj = {},
  showTimeZoneMapping = false,
}) => {
  const columnDefinitions = dataKeys.map((dataKey, i) => ({
    id: dataKey,
    header: headers[i],
    cell: (item) => {
      if (showTimeZoneMapping && dataKey === 'startTime') {
        return (
          <Popover
            dismissAriaLabel="Close"
            className="start-time-label"
            header={
              <>
                <p>Local Site Time</p>
                <p className="visually-hidden">
                  {userSiteToTimeZone[item.site]}
                </p>
              </>
            }
            size="medium"
            triggerType="text"
            content={<p>{userSiteToTimeZone[item.site]}</p>}
          >
            {item.startTime}
          </Popover>
        );
      }
      return typeof item[dataKey] !== 'undefined' ? item[dataKey] : '-';
    },
  }));

  const features = ['propertyFiltering', 'pagination', 'selection', 'sorting'];
  const sortableColumns = dataKeys.map((key) => {
    return {
      id: key,
      field: key,
      comparator: specialSortableColumnsComparatorsObj[key],
    };
  });

  const filteringOptions = dataKeys.map((key, i) => ({
    groupValuesLabel: `${headers[i]} Values`,
    propertyKey: key,
    propertyLabel: headers[i],
    values: [...new Set(data.map((item) => item[key]))],
  }));

  return (
    <div className="awsui-util-no-gutters awsui-util-mt-s">
      <Table
        columnDefinitions={columnDefinitions}
        items={data}
        wrapLines={false}
        features={features}
        loading={Boolean(loading)}
        loadingText={loadingText || 'Loading resources'}
        header={HeaderComponent ? <HeaderComponent /> : <></>}
        empty={
          EmptyComponent ? (
            <EmptyComponent />
          ) : (
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No resources</b>
              </div>
              <p className="awsui-util-mb-s">No resources to display.</p>
            </div>
          )
        }
        noMatch={
          NoMatchComponent ? (
            <NoMatchComponent />
          ) : (
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No matches</b>
              </div>
              <p className="awsui-util-mb-s">We can’t find a match.</p>
            </div>
          )
        }
      >
        {showTimeZoneMapping && (
          <Popover
            dismissAriaLabel="Close"
            className="timezone-info"
            header="Local Site Times"
            size="medium"
            triggerType="text"
            content={
              <ul
                role="region"
                aria-label="Local Site Times"
                tabIndex="0"
                className="timezone-list"
              >
                {Object.keys(userSiteToTimeZoneShortList).map((timezone) => {
                  return (
                    <li key={timezone} className="timezone-mapping">
                      <div className="timezone-label">{timezone}</div>
                      <div className="timezone-value">
                        {userSiteToTimeZoneShortList[timezone]}
                      </div>
                    </li>
                  );
                })}
              </ul>
            }
          >
            <span className="upload-info">
              <Icon name="status-info" size="normal" variant="normal" /> All
              times are from local site upload
            </span>
          </Popover>
        )}
        <TablePagination pageSize={10}></TablePagination>
        <TableSorting sortableColumns={sortableColumns}></TableSorting>
        <TableSelection
          keepSelection={true}
          isItemDisabled={isItemDisabled}
          selectionType={selectionType}
          selectedItems={currentSelection}
          onSelectionChange={(evt) => {
            setCurrentSelection(evt.detail.selectedItems);
          }}
        ></TableSelection>
        <TablePreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
        >
          <TablePageSizeSelector
            title="Page size"
            options={[
              {
                value: 10,
                label: '10 rows',
              },
              {
                value: 20,
                label: '20 rows',
              },
              {
                value: 50,
                label: '50 rows',
              },
              {
                value: 100,
                label: '100 rows',
              },
            ]}
          ></TablePageSizeSelector>
        </TablePreferences>
        <TablePropertyFiltering
          filteringOptions={filteringOptions}
          groupValuesText="Values"
          groupPropertiesText="Properties"
          operationAndText="and"
          operationNotAndText="and not"
          operationOrText="or"
          operationNotOrText="or not"
          clearFiltersText="Clear filter"
          placeholder="Filter resources by property or value"
          allowFreeTextFiltering={true}
        ></TablePropertyFiltering>
      </Table>
    </div>
  );
};
EnhancedTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  sortingColumn: PropTypes.string,
  sortingDescending: PropTypes.bool,
  dataKeys: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.object),
  HeaderComponent: PropTypes.func,
  EmptyComponent: PropTypes.element,
  NoMatchComponent: PropTypes.element,
  currentSelection: PropTypes.arrayOf(PropTypes.object),
  setCurrentSelection: PropTypes.func,
  showTimeZoneMapping: PropTypes.bool,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  selectionType: PropTypes.string,
  isItemDisabled: PropTypes.bool,
  specialSortableColumnsComparatorsObj: PropTypes.object,
};
export default EnhancedTable;
