import { ApiConfig } from '../configurations/apis';

export const API_ROOT = ApiConfig.endpoints[0].endpoint;
export const headers = ApiConfig.endpoints[0].headers;

export const GET_SCHEDULES_ENDPOINT = `${API_ROOT}/getAvailableSchedules`;
export const GET_SELECTED_SCHEDULES_ENDPOINT = `${API_ROOT}/getSelectedSchedules`;
export const ADD_AVAILABLE_SCHEDULES_ENDPOINT = `${API_ROOT}/addAvailableSchedules`;
export const EDIT_CANDIDATE_ENDPOINT = `${API_ROOT}/editCandidate`;
export const DELETE_CANDIDATE_ENDPOINT = `${API_ROOT}/deleteCandidate`;
export const ADD_CANDIDATES_ENDPOINT = `${API_ROOT}/addCandidates`;
export const GET_CANDIDATES_ENDPOINT = `${API_ROOT}/getCandidates`;
export const GET_PENDING_CANDIDATES_ENDPOINT = `${API_ROOT}/getPendingCandidates`;
export const UPLOAD_TO_ENDPOINT = `${API_ROOT}/uploadToCSSM`;
export const EDIT_SCHEDULE_AVAILABLE_NUMBER = `${API_ROOT}/editScheduleAvailableNumber`;
export const BULK_EDIT_SCHEDULE_AVAILABLE_NUMBER = `${API_ROOT}/bulkEditScheduleAvailableNumber`;
export const MULTI_EDIT_SCHEDULE_AVAILABLE_NUMBER = `${API_ROOT}/multiEditScheduleAvailableNumber`;
export const DELETE_AVAILABLE_SCHEDULE_ENDPOINT = `${API_ROOT}/deleteAvailableSchedule`;
export const MULTI_DELETE_AVAILABLE_SCHEDULE_ENDPOINT = `${API_ROOT}/batchDeleteAvailableSchedules`;
