import { 
  GET_PENDING_CANDIDATES_ENDPOINT,
  headers 
} from '../../constants/apiConfigs';
import {
  ADD_PENDING_CANDIDATES,
  PENDING_CANDIDATES_ARE_LOADING,
} from './types';
import axios from 'axios';
import { get } from 'lodash';
import { showErrorBanner } from './bannerMessages';

export function pendingCandidatesAreLoading(isLoading) {
  return {
    type: PENDING_CANDIDATES_ARE_LOADING,
    payload: isLoading,
  };
}

export function addPendingCandidates(candidates) {
  return {
    type: ADD_PENDING_CANDIDATES,
    payload: candidates,
  };
}

export function getPendingCandidatesApi() {
  return async (dispatch) => {
    dispatch(pendingCandidatesAreLoading(true));
    return axios
      .get(GET_PENDING_CANDIDATES_ENDPOINT, {
        headers: await headers()
      })
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) =>
        dispatch(addPendingCandidates(get(res, 'data.Candidates', [])))
      )
      .catch((e) => {
        dispatch(
          showErrorBanner(
            'There was an error getting the pending candidates from the server'
          )
        );
      })
      .then(() => dispatch(pendingCandidatesAreLoading(false)));
  };
}
