export const dateComparator = (keyName) => {
  return (a, b) => {
    return new Date(a[keyName]) - new Date(b[keyName]);
  };
};

export const timeComparator = (keyName) => {
  return (a, b) => {
    const aTime = a[keyName];
    const bTime = b[keyName];
    if (!aTime) {
      return -1;
    }
    if (!bTime) {
      return 1;
    }
    return (
      new Date('2020-12:25 ' + a.startTime) -
      new Date('2020-12:25 ' + b.startTime)
    );
  };
};
