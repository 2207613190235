export const userSiteToTimeZone = {
  VCS: 'Pacific Standard Time (GMT-8)',
  VCC: 'Pacific Standard Time (GMT-8)',
  VCC3: 'Pacific Standard Time (GMT-8)',
  VCSNA: 'Pacific Standard Time (GMT-8)',
  PSC: 'Pacific Standard Time (GMT-8)',
  CSTSite: 'Pacific Standard Time (GMT-8)',
  SJO: 'Central Standard Time (GMT-6)',
  SJO1: 'Central Standard Time (GMT-6)',
  'CR-VCC': 'Central Standard Time (GMT-6)',
  VCSCOL: 'Colombia Standard Time (GMT-5)',
  BOG: 'Colombia Standard Time (GMT-5)',
  VCSMEX: 'Central Standard Time (GMT-6)',
  WIN: 'Eastern Standard Time (GMT-4)',
  LEX: 'Eastern Standard Time (GMT-4)',
  LEX10: 'Eastern Standard Time (GMT-4)',
  HTS: 'Eastern Standard Time (GMT-4)',
  TEST: 'Eastern Standard Time (GMT-4)',
  BRA: 'Brasilia Standard Time (GMT-3)',
  'VCS-BRA': 'Brasilia Standard Time (GMT-3)',
  VCSBRA: 'Brasilia Standard Time (GMT-3)',
};

export const userSiteToTimeZoneShortList = {
  HTS: 'Eastern Standard Time (GMT-4)',
  LEX10: 'Eastern Standard Time (GMT-4)',
  PSC: 'Pacific Standard Time (GMT-8)',
  SJO: 'Central Standard Time (GMT-6)',
  'VCS BRA': 'Brasilia Standard Time (GMT-3)',
  'VCS COL': 'Colombia Standard Time (GMT-5)',
  'VCS CRI': 'Central Standard Time (GMT-6)',
  'VCS MEX': 'Central Standard Time (GMT-6)',
  'VCS US': 'Pacific Standard Time (GMT-8)',
};
