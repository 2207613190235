import React from 'react';
import { Button, Icon } from '@amzn/awsui-components-react';

const TableHeader = ({ title, onRefresh, buttons }) => (
  <div>
    <div className="awsui-util-action-stripe-large">
      <div className="awsui-util-action-stripe-title">
        <h1>{title}</h1>
      </div>
      <div className="awsui-util-action-stripe-group">
        <Button variant="normal" onClick={onRefresh}>
          <div aria-label="refresh">
            <Icon name="refresh" size="normal" variant="normal" />
          </div>
        </Button>
        {buttons &&
          buttons.map((button) => {
            return button;
          })}
      </div>
    </div>
  </div>
);

export default TableHeader;
